import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "moment/locale/ru";
import Baron from "react-baron/dist/es5";
import amplitude from 'amplitude-js';

import {
  createBonusId,
  deleteBonusId,
  getBonusIds,
} from "../utils/bonusesHttp";
import { SingleDatePicker } from "react-dates";
import { Popup } from "../containers/Popup";
import { connect } from "react-redux";

const BonusProgram = (props) => {
  const [error, setError] = useState("");
  const [bonusIdError, setBonusIdError] = useState("");
  const [regDateError, setRegDateError] = useState("");
  const [regDate, setRegDate] = useState(null);
  const [focusedRegDate, setFocusedRegDate] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [bonusIdValue, setBonusIdValue] = useState("");
  const [bonusIds, setBonusIds] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);

  const dateRef = useRef(null);
  const bonusIdRef = useRef(null);

  useEffect(() => {
    amplitude.getInstance().logEvent('bonus_program_page.page_view');

    getBonusIds().then((res) => {
      setBonusIds(res.data.bonus_ids);
    });
  }, []);

  useEffect(() => {
    if (
      dateRef.current &&
      regDate &&
      error &&
      error !==
        "Вы уже добавили 5 бонус ID. Удалите один из них, чтобы добавить новый"
    ) {
      setError("");
      const inputs = dateRef.current.getElementsByTagName("input");
      inputs[0].style.border = null;
    }
  }, [regDate]);

  const onSubmit = () => {
    if (!validation()) return;
    const data = {
      bonus_id: +bonusIdValue,
      registration_date:
        regDate && moment(regDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
    };

    createBonusId(data)
      .then((res) => {
        setModalOpened(false);
        setBonusIds([
          ...bonusIds,
          {
            id: res.data.id,
            bonus_id: res.data.bonus_id,
            registration_date: res.data.registration_date,
          },
        ]);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  const onDelete = (id) => {
    deleteBonusId(id).then(() => {
      setBonusIds(bonusIds.filter((bonusIdObj) => bonusIdObj.id !== id));
    });
  };

  const changeSelect = (name, value) => {
    if (value) $("." + name).addClass("isShowSelect");
    else $("." + name).removeClass("isShowSelect");
  };

  const onFocusedRegDateChange = (regDate) => {
    setRegDate(regDate);
    setRegDateError("");
    if (dateRef.current) {
      const inputs = dateRef.current.getElementsByTagName("input");
      inputs[0].style.border = null;
    }
  };

  const validation = () => {
    let outputValue = true;

    if (bonusIdValue === "") {
      setBonusIdError("Обязательное поле");
      if (bonusIdRef.current)
        bonusIdRef.current.style.borderBottom = "1px solid #f44336";
      outputValue = false;
    }
    if (bonusIdValue.length < 8) {
      setBonusIdError("Бонус ID состоит из 8 и более символов, проверьте ввод");
      if (bonusIdRef.current)
        bonusIdRef.current.style.borderBottom = "1px solid #f44336";
      outputValue = false;
    } else if (bonusIdValue.length > 15) {
      setBonusIdError(
        "Бонус ID состоит из меньшего числа символов, проверьте ввод"
      );
      if (bonusIdRef.current)
        bonusIdRef.current.style.borderBottom = "1px solid #f44336";
      outputValue = false;
    } else if (
      bonusIdValue
        .split("")
        .filter((c) =>
          ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(String(c))
        ).length !== bonusIdValue.length
    ) {
      setBonusIdError(
        "Бонус ID состоит только из цифр. Проверьте правильность ввода"
      );
      if (bonusIdRef.current)
        bonusIdRef.current.style.borderBottom = "1px solid #f44336";
      outputValue = false;
    } else {
      setBonusIdError("");
      if (bonusIdRef.current) bonusIdRef.current.style.borderBottom = null;
    }
    if (regDate === null) {
      setRegDateError("Обязательное поле");
      outputValue = false;
      if (dateRef.current) {
        const inputs = dateRef.current.getElementsByTagName("input");
        inputs[0].style.border = "1px solid #f44336";
      }
    } else if (Math.abs((regDate - moment.now()) / 1000 / 3600) / 24 > 365) {
      setRegDateError(
        "Аккаунт создан более 1 года назад. Зарегистрируйте новый аккаунт по ссылке"
      );
      outputValue = false;
      if (dateRef.current) {
        const inputs = dateRef.current.getElementsByTagName("input");
        inputs[0].style.border = "1px solid #f44336";
      }
    } else {
      setRegDateError("");
      if (dateRef.current) {
        const inputs = dateRef.current.getElementsByTagName("input");
        inputs[0].style.border = null;
      }
    }
    if (!outputValue) return outputValue;
    if (bonusIds.length >= 5) {
      setError(
        "Вы уже добавили 5 бонус ID. Удалите один из них, чтобы добавить новый"
      );
      if (bonusIdRef.current)
        bonusIdRef.current.style.borderBottom = "1px solid #f44336";
      if (dateRef.current) {
        const inputs = dateRef.current.getElementsByTagName("input");
        inputs[0].style.border = "1px solid #f44336";
      }
      return false;
    }
    return outputValue;
  };

  const renderMonthElement = ({
    month,
    onMonthSelect,
    onYearSelect,
    isValidUntil,
  }) => {
    let rows = [];
    for (let i = 0; i < 100; i++) {
      rows.push(i);
    }

    const addToYear = isValidUntil ? 50 : 0;

    return (
      <div
        className="singleDatePickerSelect"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="customSelect customSelectSingleDatepicker">
          <div
            className="customSelectedItem"
            onClick={() => {
              changeSelect("customSelectListMonth", true);
            }}
          >
            {month.format("MMMM")}
          </div>
          {
            <ul className="customSelectList customSelectListMonth">
              <Baron>
                {moment.months().map((label, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      onMonthSelect(month, index);
                      changeSelect("customSelectListMonth", false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
        <div className="customSelect customSelectSingleDatepicker customSelectSingleDatepicker-year">
          <div
            className="customSelectedItem"
            onClick={() => {
              changeSelect("customSelectListYear", true);
            }}
          >
            {month.year()}
          </div>
          {
            <ul className="customSelectList customSelectListYear">
              <Baron>
                {rows.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      onYearSelect(month, moment().year() + addToYear - row);
                      changeSelect("customSelectListYear", false);
                    }}
                  >
                    {moment().year() + addToYear - row}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
      </div>
    );
  };

  const onBonusIdValueChange = (e) => {
    const value = e.target.value
      .split("")
      .filter((c) =>
        ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(String(c))
      )
      .join("");

    setBonusIdValue(value);
    setBonusIdError("");
    if (bonusIdRef.current) bonusIdRef.current.style.borderBottom = null;
    if (error === "Невозможно создать Бонус ID") {
      setError("");
    }
  };

  return (
    <div>
      {modalOpened ? (
        <Popup closePopup={() => {
          setModalOpened(false);
          setError("");
          setBonusIdError("");
          setRegDateError("");
        }} popupClass="googleAuth">
          <form>
            <div>
              <div className="card-header p-0">
                <h2 className="card-title">Добавление Bonus ID</h2>
              </div>
              <div className="card-body p-0 mt-4">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-6 col-xl-5 col-form-label text-left">
                      Бонус ID
                    </label>
                    <div className="col-6 col-xl-7">
                      <input
                        type="text"
                        className={`inputUnderLine ${
                          bonusIdError ? "inputValidateCurrentWrong" : ""
                        }`}
                        placeholder="Бонус ID"
                        value={bonusIdValue}
                        onChange={onBonusIdValueChange}
                        ref={bonusIdRef}
                      />
                      {bonusIdError ? (
                        <div className="text-danger">{bonusIdError}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 row">
                  <label className="col-6 col-xl-5 col-form-label text-left">
                    Дата регистрации
                  </label>
                  <div className="formField-container col-6 col-xl-7">
                    <div
                      className="dateBubble"
                      ref={dateRef}
                      style={{
                        position: "static",
                        display: "inline-block",
                        width: "85px",
                        marginLeft: "5px",
                      }}
                    >
                      <SingleDatePicker
                        date={regDate}
                        placeholder=""
                        onDateChange={onFocusedRegDateChange}
                        focused={focusedRegDate}
                        onFocusChange={() => {
                          setFocusedRegDate(!focusedRegDate);
                        }}
                        numberOfMonths={1}
                        isOutsideRange={() => false}
                        hideKeyboardShortcutsPanel={true}
                        renderMonthElement={(e) =>
                          renderMonthElement({ ...e, isValidUntil: false })
                        }
                      />
                    </div>
                    {regDateError && <div className="text-danger mt-1">{regDateError}</div>}
                  </div>
                </div>
                {error && <div className="text-danger">{error}</div>}
              </div>
              <div className="popupSubmitBtns d-flex justify-content-between align-items-center">
                <div
                  className="popupCancel"
                  onClick={() => setModalOpened(false)}
                >
                  Отмена
                </div>
                <div
                  className="popupSubmitBtn popupSubmitBtn-ok"
                  onClick={onSubmit}
                >
                  Сохранить
                </div>
              </div>
            </div>
          </form>
        </Popup>
      ) : null}

      <div className="page-content-center">
        <h1 className="title card-content">Бонусная программа</h1>
        <div className="card card-content">
          <div
            className={`bonusProgram-container ${
              isExpanded ? "expanded" : ""
            }`}
            style={{ width: "80%" }}
          >
            <h4 className="mb-0">Как работает программа</h4>
            {isExpanded ? (
              <React.Fragment>
                <ol className="mt-3">
                  {props.settings.BONUS_REF_LINK ? <li>
                    Регистрируйтесь по ссылке:{" "}
                    <a
                      href={props.settings.BONUS_REF_LINK}
                      target="_blank"
                    >
                      {props.settings.BONUS_REF_LINK}
                    </a>
                  </li> : null}
                  <li>
                    Добавьте ID вашего аккаунта и дату регистрации в форму ниже
                  </li>
                  <li>
                    После модерации ваш ID будет подключен к программе. Модерация занимает примерно сутки
                  </li>
                </ol>
                <div className="mt-3">
                  <div className="bonusInfo">Можно добавить до 5 бонус ID</div>
                  <div className="bonusInfo">
                    Срок регистрации аккаунта должен быть не более года
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
          <div
            className={`expand ${!isExpanded ? "active" : ""}`}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <img src="/images/ui/cross.png" alt="Cross" />
          </div>
        </div>
      </div>
      <div className="card-content bonusId-section">
        <div className="row mx-0 align-items-center justify-content-between">
          <h4>Бонус ID</h4>
          <button
            className="btn btn-primary bonusId-addBtn"
            onClick={(e) => {
              e.preventDefault();
              setModalOpened(true);
            }}
          >
            Добавить
          </button>
        </div>
        <div className="table mt-4">
          <table className="tableLayoutFixes">
            <thead>
              <tr>
                <td>БОНУС ID</td>
                <td>ДАТА РЕГИСТРАЦИИ</td>
                <td className="delete"></td>
              </tr>
            </thead>
          </table>
          <Baron>
            <table className="tableLayoutFixes docsTable">
              <tbody>
                {bonusIds.map((bonusIdObj) => {
                  return (
                    <tr>
                      <td>{bonusIdObj.bonus_id}</td>
                      <td>{bonusIdObj.registration_date}</td>
                      <td
                        className="delete"
                        onClick={() => onDelete(bonusIdObj.id)}
                      >
                        <span className="icon-cross3 text-danger dashboard-instCloseBtn"></span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Baron>
        </div>
      </div>
      <div className="page-content-center">
        <div className="card card-content">
          <div className="bonusProgram-container">
            <h4>Как обменять бонусы</h4>
            <div className="row mt-3">
              <div className="col-8">
                <div>Потребуется кошелек сети TRC20.</div>
                <ol className="mt-3">
                  <li>
                    Перейдите в раздел <a href="/app/spend-bonuses">Обмен бонусов</a> и нажмите на «Обменять бонусы»                 
                  </li>
                  <li>
                    Создайте запрос на конвертацию бонусных баллов, следуя подсказкам на экране
                  </li>
                  <li>
                    После модерации запроса сумма поступит на личный кошелек
                  </li>
                </ol>
              </div>
              <div className="col-4">
                <div className="bonusInfo">1 VB = 1 USDT</div>
                <div className="bonusInfo">От 300 VB — без комиссии</div>
                <div className="bonusInfo">До 300 VB — комиссия 1,5 VB</div>
                <div className="bonusInfo">
                  Без ограничений по частоте вывода
                </div>
              </div>
            </div>
            <div className="mt-3 warnInfo">
              <img
                src="/images/ui/warn-icon.png"
                width="20px"
                height="20px"
                style={{ marginRight: "10px" }}
              />
              Бонусные баллы не конвертируются напрямую на торговый кошелек
              Binance
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(BonusProgram);