import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import ReactTooltip from 'react-tooltip';

import { currencyFormat } from '../utils/currencyUtil';
import { connect } from 'react-redux';

class Header extends Component {
  createBalanceList = () => {
    let list = [];
    const balanceList = this.props.balance;
    for (let key in balanceList) {
      if (list.length > 0) list.push(<br />);

      list.push(
        <span key={`${key} trading`}>
          {currencyFormat(balanceList[key].trading, key)} /{' '}
        </span>
      );
      list.push(
        <span key={`${key} security`}>
          {currencyFormat(balanceList[key].security, key)}
        </span>
      );
    }
    return list;
  };
  render() {
    const {
      accounts,
      user,
      openSupportWindow,
      onLogout,
      openChatWindow,
    } = this.props;
    return (
      <div className="navbar navbar-expand-md navbar-dark fixed-top">
        <NotificationContainer />
        <div className="navbar-brand">
          <Link to="../full/index.html" className="d-inline-block"></Link>
        </div>

        <div className="d-md-none">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-mobile"
          >
            <i className="icon-user"></i>
          </button>
          <button
            className="navbar-toggler sidebar-mobile-main-toggle"
            type="button"
          >
            <i className="icon-paragraph-justify3"></i>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbar-mobile">
          <ul className="navbar-nav">
            <li className="nav-item">
              <span className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
                <i className="icon-paragraph-justify3"></i>
              </span>
            </li>
            <li
              className="nav-item dropdown"
              onClick={openSupportWindow}
              data-tip
              data-for="techSupportTooltip"
            >
              <span className="navbar-nav-link caret-0">
                <i className="icon-envelop3"></i>
                <span className="d-md-none ml-2">Тех.поддержка</span>
              </span>
            </li>
            <li
              className="nav-item dropdown"
              onClick={openChatWindow}
              data-tip
              data-for="chatTooltip"
            >
              <span className="navbar-nav-link caret-0">
                <i className="icon-bubbles9"></i>
                <span className="d-md-none ml-2">Чат</span>
              </span>
            </li>
            <li className="nav-item dropdown" data-tip data-for="kbTooltip">
              <a
                href={`${process.env.REACT_APP_CHAT_DOMAIN}/?sso_token=${user.sso_token}`}
                className="navbar-nav-link caret-0"
              >
                <i className="icon-help"></i>
                <span className="d-md-none ml-2">Форум</span>
              </a>
            </li>
          </ul>
          <ReactTooltip
            place="bottom"
            id="techSupportTooltip"
            type="dark"
            effect="solid"
          >
            <span>Тех. поддержка</span>
          </ReactTooltip>
          <ReactTooltip
            place="bottom"
            id="chatTooltip"
            type="dark"
            effect="solid"
          >
            <span>Чат</span>
          </ReactTooltip>
          <ReactTooltip
            place="bottom"
            id="kbTooltip"
            type="dark"
            effect="solid"
          >
            <span>База знаний</span>
          </ReactTooltip>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a
                href="#"
                className={`navbar-nav-link dropdown-toggle caret-0 ${
                  this.createBalanceList().length > 2
                    ? 'balanceMoreCurrency'
                    : 'balanceCurrency'
                }`}
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{this.createBalanceList()}</span>
              </a>

              <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
                <div className="dropdown-content-header pb-2">
                  <h5 className="font-weight-semibold">Торговые счета</h5>
                </div>
                <div className="dropdown-content-body dropdown-scrollable">
                  <ul className="media-list">
                    {accounts.map((account, index) => (
                      <li className="media" key={account.name}>
                        <div className="media-body">
                          <div className="media-title">
                            <span className="font-weight-semibold">
                              {account.name}
                            </span>
                            {account.balance.security && (
                              <span className="text-muted float-right font-size-sm">
                                {currencyFormat(
                                  account.balance.security,
                                  account.currency
                                )}
                              </span>
                            )}
                            {account.balance.trading && (
                              <span className="text-muted float-right font-size-sm">
                                {currencyFormat(
                                  account.balance.trading,
                                  account.currency
                                )}
                                {account.balance.trading &&
                                account.balance.security ? (
                                  <span style={{ marginRight: '3px' }}>
                                    {' '}
                                    /{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </li>

            <li className="nav-item dropdown dropdown-user">
              <a
                to="#"
                className="navbar-nav-link d-flex align-items-center dropdown-toggle"
                data-toggle="dropdown"
              >
                <span>Пользователь</span>
              </a>

              <div className="dropdown-menu dropdown-menu-right">
                <Link to="/app/profile" className="dropdown-item">
                  <i className="icon-user"></i> Профиль
                </Link>
                <span className="dropdown-item" onClick={onLogout}>
                  <i className="icon-switch2"></i> Выход
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  accounts: PropTypes.array,
  user: PropTypes.object,
  openSupportWindow: PropTypes.func,
  onLogout: PropTypes.func,
  openChatWindow: PropTypes.func,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
});

export default connect(mapStateToProps)(Header);