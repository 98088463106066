import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "moment/locale/ru";
import Baron from "react-baron/dist/es5";
import amplitude from 'amplitude-js';

import { requestBonusId } from "../utils/bonusesHttp";
import { SingleDatePicker } from "react-dates";
import { connect } from "react-redux";

const RequestBonusProgram = ({ settings, user }) => {
  const [error, setError] = useState("");
  const [bonusIdError, setBonusIdError] = useState("");
  const [regDateError, setRegDateError] = useState("");
  const [regDate, setRegDate] = useState(null);
  const [focusedRegDate, setFocusedRegDate] = useState(false);
  const [bonusIdValue, setBonusIdValue] = useState("");
  const [success, setSuccess] = useState(false);

  const dateRef = useRef(null);
  const bonusIdRef = useRef(null);

  useEffect(() => {
    amplitude.getInstance().logEvent('request_bonus_program_page.page_view');
  }, []);

  const onSubmit = () => {
    if (success || !validation()) return;
    const data = {
      bonus_id: +bonusIdValue,
      registration_date:
        regDate && moment(regDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
    };

    requestBonusId(data)
      .then(() => setSuccess(true))
      .catch((err) => {
        setError(err.response.data.message && err.response.data.message.error);
      });
  };

  const changeSelect = (name, value) => {
    if (value) $("." + name).addClass("isShowSelect");
    else $("." + name).removeClass("isShowSelect");
  };

  const onFocusedRegDateChange = (regDate) => {
    setRegDate(regDate);
    setRegDateError("");
    if (dateRef.current) {
      const inputs = dateRef.current.getElementsByTagName("input");
      inputs[0].style.border = null;
    }
  };

  const validation = () => {
    let outputValue = true;

    if (bonusIdValue === "") {
      setBonusIdError("Обязательное поле");
      if (bonusIdRef.current)
        bonusIdRef.current.style.borderBottom = "1px solid #f44336";
      outputValue = false;
    } else if (bonusIdValue.length < 8) {
      setBonusIdError("Бонус ID состоит из 8 и более символов, проверьте ввод");
      if (bonusIdRef.current)
        bonusIdRef.current.style.borderBottom = "1px solid #f44336";
      outputValue = false;
    } else if (bonusIdValue.length > 15) {
      setBonusIdError(
        "Бонус ID состоит из меньшего числа символов, проверьте ввод"
      );
      if (bonusIdRef.current)
        bonusIdRef.current.style.borderBottom = "1px solid #f44336";
      outputValue = false;
    } else if (
      bonusIdValue
        .split("")
        .filter((c) =>
          ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(String(c))
        ).length !== bonusIdValue.length
    ) {
      setBonusIdError(
        "Бонус ID состоит только из цифр. Проверьте правильность ввода"
      );
      if (bonusIdRef.current)
        bonusIdRef.current.style.borderBottom = "1px solid #f44336";
      outputValue = false;
    } else {
      setBonusIdError("");
      if (bonusIdRef.current) bonusIdRef.current.style.borderBottom = null;
    }
    if (regDate === null) {
      setRegDateError("Обязательное поле");
      outputValue = false;
      if (dateRef.current) {
        const inputs = dateRef.current.getElementsByTagName("input");
        inputs[0].style.border = "1px solid #f44336";
      }
    } else if (Math.abs((regDate - moment.now()) / 1000 / 3600) / 24 > 365) {
      setRegDateError(
        "Аккаунт создан более 1 года назад. Зарегистрируйте новый аккаунт по ссылке"
      );
      outputValue = false;
      if (dateRef.current) {
        const inputs = dateRef.current.getElementsByTagName("input");
        inputs[0].style.border = "1px solid #f44336";
      }
    } else {
      setRegDateError("");
      if (dateRef.current) {
        const inputs = dateRef.current.getElementsByTagName("input");
        inputs[0].style.border = null;
      }
    }
    if (!outputValue) return outputValue;
    return outputValue;
  };

  const renderMonthElement = ({
    month,
    onMonthSelect,
    onYearSelect,
    isValidUntil,
  }) => {
    let rows = [];
    for (let i = 0; i < 100; i++) {
      rows.push(i);
    }

    const addToYear = isValidUntil ? 50 : 0;

    return (
      <div
        className="singleDatePickerSelect"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="customSelect customSelectSingleDatepicker">
          <div
            className="customSelectedItem"
            onClick={() => {
              changeSelect("customSelectListMonth", true);
            }}
          >
            {month.format("MMMM")}
          </div>
          {
            <ul className="customSelectList customSelectListMonth">
              <Baron>
                {moment.months().map((label, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      onMonthSelect(month, index);
                      changeSelect("customSelectListMonth", false);
                    }}
                  >
                    {label}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
        <div className="customSelect customSelectSingleDatepicker customSelectSingleDatepicker-year">
          <div
            className="customSelectedItem"
            onClick={() => {
              changeSelect("customSelectListYear", true);
            }}
          >
            {month.year()}
          </div>
          {
            <ul className="customSelectList customSelectListYear">
              <Baron>
                {rows.map((row) => (
                  <li
                    key={row}
                    onClick={() => {
                      onYearSelect(month, moment().year() + addToYear - row);
                      changeSelect("customSelectListYear", false);
                    }}
                  >
                    {moment().year() + addToYear - row}
                  </li>
                ))}
              </Baron>
            </ul>
          }
        </div>
      </div>
    );
  };

  const onBonusIdValueChange = (e) => {
    const value = e.target.value
      .split("")
      .filter((c) =>
        ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(String(c))
      )
      .join("");

    setBonusIdValue(value);
    setBonusIdError("");
    if (bonusIdRef.current) bonusIdRef.current.style.borderBottom = null;
  };

  return (
    <div>
      <div className="page-content-center">
        <h1 className="title card-content">
          Стать участником бонусной программы
        </h1>
        {user.bonus_status === "NEED_UPDATE" ? (
          <div className="formWarnAlert warn">
            <div className="d-flex align-items-center">
              <img src="/images/ui/alert-triangle.svg" />
              <h3>Требуется повторная отправка заявки.</h3>
            </div>
            <p>{user.bonus_update_reason}</p>
          </div>
        ) : null}
        <div className="card card-content">
          <div className="bonusProgram-container w-75">
            <h4 className="mb-0">Как работает программа</h4>
            <React.Fragment>
              <ol className="mt-3">
                {settings.BONUS_REF_LINK ? <li>
                  Регистрируйтесь по ссылке:{" "}
                  <a
                    href={settings.BONUS_REF_LINK}
                    target="_blank"
                  >
                    {settings.BONUS_REF_LINK}
                  </a>
                </li> : null}
                <li>
                  Добавьте ID вашего аккаунта и дату регистрации в форму ниже
                </li>
                <li>
                  После модерации бонус ID вы становитесь участником программы.
                  Процесс модерации занимает 1 сутки
                </li>
              </ol>
              <div className="mt-3">
                <div className="bonusInfo">
                  Срок регистрации аккаунта должен быть не более года
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
        <div style={{ width: 700 }} className="m-auto">
          <div className="card card-content m-0" style={{ width: "60%" }}>
            <div className="bonusProgram-container">
              <h6 className="mb-0">Подключиться к бонусной программе</h6>
              <div className="col-12 mt-4">
                <div className="form-group row">
                  <label className="col-6 col-xl-5 col-form-label text-left px-0">
                    Бонус ID
                  </label>
                  <div className="col-6 col-xl-7">
                    <input
                      disabled={success}
                      type="text"
                      className={`inputUnderLine ${
                        bonusIdError ? "inputValidateCurrentWrong" : ""
                      }`}
                      placeholder="Бонус ID"
                      value={bonusIdValue}
                      onChange={onBonusIdValueChange}
                      ref={bonusIdRef}
                    />
                    {bonusIdError ? (
                      <div className="text-danger">{bonusIdError}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-12 row">
                <label className="col-6 col-xl-5 col-form-label text-left px-0">
                  Дата регистрации
                </label>
                <div className="formField-container col-6 col-xl-7">
                  <div
                    className="dateBubble"
                    ref={dateRef}
                    style={{
                      position: "static",
                      display: "inline-block",
                      width: "85px",
                      marginLeft: "5px",
                    }}
                  >
                    <SingleDatePicker
                      date={regDate}
                      placeholder=""
                      onDateChange={onFocusedRegDateChange}
                      focused={focusedRegDate}
                      onFocusChange={() => {
                        setFocusedRegDate(!focusedRegDate);
                      }}
                      disabled={success}
                      numberOfMonths={1}
                      isOutsideRange={() => false}
                      hideKeyboardShortcutsPanel={true}
                      renderMonthElement={(e) =>
                        renderMonthElement({ ...e, isValidUntil: false })
                      }
                    />
                  </div>
                  {regDateError && (
                    <div className="text-danger mt-1">{regDateError}</div>
                  )}
                </div>
              </div>
              {error ? <div className="text-danger mt-1">{error}</div> : null}
              <div className="popupSubmitBtns">
                <div
                  className={`popupSubmitBtn popupSubmitBtn-ok ${
                    success ? "opacity-50" : ""
                  }`}
                  style={success ? { cursor: "default" } : null}
                  onClick={onSubmit}
                >
                  Оставить заявку
                </div>
              </div>
              {success && (
                <div
                  className="d-flex text-success align-items-center mt-2"
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/images/ui/check-icon-black.png"
                    className="filterToGreen"
                    alt="Ready"
                    height="12px"
                  />
                  <div>
                    Заявка создана. Вы получите уведомление по результатам
                    модерации
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    user: state.user,
  };
};

export default connect(mapStateToProps)(RequestBonusProgram);
