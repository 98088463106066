import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import { logout, catchPromise } from '../utils/http';
import { getTradingAccounts } from '../utils/transactionsHttp';
import { setTradingAccounts } from '../actions/tradingAccounts';
import HeaderComponent from '../components/Header';

export class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: props.tradingAccounts.trading_accounts,
      balance: props.tradingAccounts.balance_total,
    };
  }
  errorCb = () => {
    // alert('Произошла ошибка при получении баланса');
  };
  componentDidMount() {
    this.loadHeaderData();
  }
  loadHeaderData = () => {
    getTradingAccounts()
      .then((res) => {
        this.props.setTradingAccounts(res.data);
      })
      .catch((err) => {
        catchPromise(err, this.loadHeaderData, this.errorCb);
      });
  };

  openSupportWindow = () => {
    // Функция для окна поддержки
    window.UE.Popin.show();
  };

  onLogout = (e) => {
    e.preventDefault();
    var elementUserEcho = document.getElementById('script_userecho');
    if (elementUserEcho) {
      elementUserEcho.parentNode.removeChild(elementUserEcho);
    }
    var elementChat = document.getElementById('chat');
    if (elementChat) {
      elementChat.parentNode.removeChild(elementChat);
    }
    var elementChatIframe = document.getElementById('chat-iframe-container');
    if (elementChatIframe) {
      elementChatIframe.parentNode.removeChild(elementChatIframe);
    }
    window.UE = null;
    logout();
  };

  openChatWindow = () => {
    // Функция для окна чата
    if (window.UE.Chat.is_online() === null) {
      NotificationManager.error('Чат недоступен', '', 2000);
      return;
    }
    if (window.UE.Chat.is_online() === false) {
      NotificationManager.warning('Операторов нет онлайн', '', 2000);
    } else {
      window.UE.Chat.open();
    }
  };

  render() {
    return (
      <HeaderComponent
        balance={this.props.tradingAccounts.balance_total}
        accounts={this.props.tradingAccounts.trading_accounts}
        user={this.props.user}
        openSupportWindow={this.openSupportWindow}
        onLogout={this.onLogout}
        openChatWindow={this.openChatWindow}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tradingAccounts: state.tradingAccounts,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTradingAccounts: (tradingAccounts) =>
    dispatch(setTradingAccounts(tradingAccounts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
