import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Baron from 'react-baron/dist/es5';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import amplitude from 'amplitude-js';

import { catchPromise } from '../utils/http';
import {
  getProfileData,
  setUserSettings,
  changeUserPassword,
  changeUserPasswordTrading,
} from '../utils/userHttp';
import { createTooltip } from '../utils/viewUtils';
import Popup from '../containers/Popup';
import { setHideTradersBalances } from '../actions/general';
import { PasswordInstructions } from './PasswordInstructions';
import { PasswordMask } from './PasswordMask';

const isCrypto = true;

export class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      platform_accounts: [],
      user: {
        first_name: '',
        last_name: '',
        middle_name: '',
        rating_nickname: '',
        email: '',
        phone: '',
        skype: '',
      },
      originalSkype: '',
      originalNickname: '',
      currentPass: '',
      currentPassVisible: '',
      pass: '',
      passVisible: false,
      repeatPass: '',
      repeatPassVisible: false,
      hideTradersBalances: false,
      isLiveNotifications: false,
      isShowPopupChange: false,
      isShowPopupChangePassword: false,
      diadocPhone: '',
      bonusId: '',
      bonusIds: [],
      usdtWallet: '',
      originalBonusId: '',
      originalUsdtWallet: '',
      originalDiadocPhone: '',
      errorMessage: '',
      isLoading: true,
      pageError: false,
      bonusIdItemWidth: null,
      googleAuthRemoveModalIsOpened: false,
      passwordErrorTypes: null
    };
  }
  errorCb = () => {
    this.setState({ pageError: true });
    this.setState({
      isShowPopupChange: false,
      isShowPopupChangePassword: false,
    });
  };
  componentDidMount() {
    amplitude.getInstance().logEvent('profile_page.page_view');
    this.loadProfileData();
  }
  togglePopup = (popupShowValue, isGoogleAuth) => {
    if (popupShowValue && !isGoogleAuth)
      this.setState({ 
        pass: '',
        passVisible: false,
        repeatPass: '',
        repeatPassVisible: false,
        currentPass: '',
        currentPassVisible: false,
        errorMessage: ''
      });
    this.setState({ [popupShowValue]: !this.state[popupShowValue] });
  };

  setBonusIdItemWidth = () => {
    const bonusIdItems = document.getElementsByClassName('bonusIdItemText');
    let width;
    if (bonusIdItems.length) {
      width = [...bonusIdItems].reduce((max, cur) => {
        if (parseFloat(max) < parseFloat(getComputedStyle(cur).width))
          return getComputedStyle(cur).width;
        else return max;
      }, getComputedStyle(bonusIdItems[0]).width);
    }
    this.setState({ bonusIdItemWidth: width });
  }

  // Settings
  loadProfileData = () => {
    this.setState({ isLoading: true });

    getProfileData()
      .then((res) => {
        this.setState({
          hideTradersBalances: this.props.hideTradersBalances,
          platform_accounts: res.data.platform_accounts,
          user: res.data.user,
          originalSkype: res.data.user.skype,
          originalNickname: res.data.user.rating_nickname,
          diadocPhone: res.data.user.phone,
          originalDiadocPhone: res.data.user.phone,
          bonusIds: res.data.user.bonus_ids,
          usdtWallet: res.data.user.usdt_wallet,
          isLoading: false,
          pageError: false,
        }, () => {
          this.setBonusIdItemWidth();
        });
      })
      .catch((err) => {
        catchPromise(err, this.loadProfileData, this.errorCb);
      });
  };
  onChangeSkype = (e) => {
    const skype = e.target.value;
    this.setState(() => ({ user: { ...this.state.user, skype } }));
  };
  onChangeNickname = (e) => {
    const rating_nickname = e.target.value;
    this.setState(() => ({ user: { ...this.state.user, rating_nickname } }));
  };
  onChangeLiveNotifications = () => {
    this.setState(
      { isLiveNotifications: !this.state.isLiveNotifications },
      () => {
        this.saveData('notifications');
      }
    );
  };
  onChangeHideTradersBalances = () => {
    const hideTradersBalances = this.state.hideTradersBalances;

    this.setState(
      { hideTradersBalances: !this.state.hideTradersBalances },
      () => {
        localStorage.setItem('hideTradersBalances', !hideTradersBalances);
        this.props.setHideTradersBalances(!hideTradersBalances);
      }
    );
  };
  onChangeBonusId = (e) => {
    const bonusId = e.target.value;
    if (isNaN(bonusId)) return;
    this.setState(() => ({bonusId: Math.abs(parseInt(bonusId))}));
  };
  onChangeUsdtWallet = (e) => {
    const usdtWallet = e.target.value;
    this.setState(() => ({usdtWallet}));
  };

  saveData = (dataType) => {
    let settings = {};
    if (dataType == 'notifications')
      settings = {
        settings: { live_notifications: this.state.isLiveNotifications },
      };
    else if (dataType == 'skype')
      settings = { user: { skype: this.state.user.skype } };
    else if (dataType == 'nickname')
      settings = { user: { rating_nickname: this.state.user.rating_nickname } };
    else if (dataType == 'diadocPhone')
      settings = { settings: { diadoc_phone: this.state.diadocPhone } };
    else if (dataType == 'bonusIds') {
      this.setState({ bonusIdItemWidth: null }, () => {
        this.setBonusIdItemWidth();
      }) 
      settings = { user: { bonus_ids: this.state.bonusIds.map(({ bonus_id }) => bonus_id ) } };
    }
    else if (dataType == 'usdtWallet')
      settings = { user: { usdt_wallet: this.state.usdtWallet } };

    setUserSettings(settings)
      .then((res) => {
        this.setState({ 
          originalSkype: this.state.user.skype, 
          originalDiadocPhone: this.state.diadocPhone, 
          originalNickname: this.state.user.rating_nickname 
        });
      })
      .catch((err) => {
        catchPromise(err, this.saveData, this.errorCb);
      });
  };

  // Change password Popup
  onSubmitChangePassword = () => {
    if (this.state.currAccName === 'website') {
      this.changeWebsitePassword();
    } else {
      this.changeTerminalPassword();
    }
  };
  changeTerminalPassword = () => {
    if (!this.state.currentPass) {
      this.setState({ errorMessage: 'Введите текущий пароль.' });
      return;
    }
    if (!this.state.pass || !this.state.repeatPass) {
      this.setState({ errorMessage: 'Введите новый пароль.' });
      return;
    }
    if (this.state.pass.length < 6) {
      this.setState({ errorMessage: 'Пароль должен быть не менее 6 символов' });
      return;
    }
    if (this.state.pass !== this.state.repeatPass) {
      this.setState({ errorMessage: 'Пароли не совпадают' });
      return;
    }

    changeUserPasswordTrading(this.state.currAccName, this.state.pass)
      .then((res) => {
        this.setState({ errorMessage: '' });
        this.togglePopup('isShowPopupChangePassword');
      })
      .catch((err) => {
        this.setState({ pass: '', repeatPass: '', currentPass: '' });
        catchPromise(err, this.changeTerminalPassword, this.errorCb);
      });
  };
  changeWebsitePassword = () => {
    if (!this.state.pass) {
      this.setState({ errorMessage: 'Введите пароль' });
      return;
    }
    if (this.state.pass !== this.state.repeatPass) {
      this.setState({ errorMessage: 'Пароли не совпадают' });
      return;
    }
    this.setState({ errorMessage: '' });

    changeUserPassword(this.state.pass, this.state.repeatPass, this.state.currentPass)
      .then((res) => {
        this.setState({ errorMessage: '' });
        this.togglePopup('isShowPopupChangePassword');
      })
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data.password_error_message && err.response.data.password_error_types) {
            this.setState({ passwordErrorTypes: err.response.data.password_error_types })
          } else {
            this.setState({ errorMessage: err.response.data.message });
          }
        } else {
          catchPromise(err, this.changeWebsitePassword, this.errorCb);
        }
      });
  };

  removeBonusId = (bonusId) => {
    const bonusIds = this.state.bonusIds.filter(({ bonus_id }) => bonusId !== bonus_id);
    this.setState({ bonusIds }, () => {
      this.saveData('bonusIds');
    });
  }

  addBonusIdInput = () => {
    this.setState({ bonusIdInputVisible: true });
  }

  addBonusId = (bonusId) => {
    this.setState({ bonusId: '', bonusIds: [...this.state.bonusIds, { bonus_id: parseInt(bonusId) }] }, () => {
      this.saveData('bonusIds');
    });
  }

  onChangeCurrentPassword = (e) => {
    let currentPass = e.target.value;
    this.setState(() => ({ 
      currentPass, 
      passwordErrorTypes: null, 
    }));
  };
  onChangeNewPassword = (e) => {
    let pass = e.target.value;
    this.setState(() => ({ 
      pass, 
      passwordErrorTypes: null, 
    }));
  };
  onChangeConfirmNewPassword = (e) => {
    let repeatPass = e.target.value;
    this.setState(() => ({ 
      repeatPass, 
      passwordErrorTypes: null, 
    }));
  };

  render() {
    return (
      <div className="settings">
        {this.state.isShowPopupChange ? (
          <Popup closePopup={() => this.togglePopup('isShowPopupChange')}>
            <div className="docPageSendMessage">
              {this.props.user.form_status == 'APPROVED' ||
              this.props.user.form_status == 'NEED_UPDATE' ||
              this.props.user.form_status == 'UPDATED' ? (
                <div>Чтобы изменить анкетные данные создайте документ</div>
              ) : (
                <div>
                  Изменение анкетных данных невозможно пока не подтверждена
                  текущая анкета
                </div>
              )}
            </div>
            <div className="popupSubmitBtns d-flex justify-content-between align-items-center">
              {this.props.user.form_status == 'APPROVED' ||
              this.props.user.form_status == 'NEED_UPDATE' ||
              this.props.user.form_status == 'UPDATED' ? (
                <div
                  className="popupCancel"
                  onClick={() => this.togglePopup('isShowPopupChange')}
                >
                  Отмена
                </div>
              ) : (
                <div></div>
              )}
              {this.props.user.form_status == 'APPROVED' ||
              this.props.user.form_status == 'NEED_UPDATE' ||
              this.props.user.form_status == 'UPDATED' ? (
                <Link
                  to="/app/documents/create?code=CHANGE_PERSONAL_FORM"
                  className="popupSubmitBtn popupSubmitBtn-ok"
                >
                  Перейти
                </Link>
              ) : (
                <div
                  className="popupSubmitBtn popupSubmitBtn-ok"
                  onClick={() => this.togglePopup('isShowPopupChange')}
                >
                  ОК
                </div>
              )}
            </div>
          </Popup>
        ) : null}
        {this.state.isShowPopupChangePassword ? (
          <Popup
            closePopup={() => this.togglePopup('isShowPopupChangePassword')}
            popupClass="changePassword"
          >
            <div className="popup-title mb-3">Смена пароля</div>
            <div className="position-relative formField-container d-flex">
              <label
                className={`floating-label ${
                  this.state.currentPass == '' ? 'hidden' : ''
                }`}
              >
                Текущий пароль
              </label>
              <input
                type={this.state.currentPassVisible ? "text" : "password"}
                className={`inputUnderLine`}
                placeholder="Текущий пароль"
                value={this.state.currentPass}
                onChange={this.onChangeCurrentPassword}
                maxLength={128}
              />
              <PasswordMask
                isInputOutlined={true}
                passwordVisible={this.state.currentPassVisible}
                setPasswordVisible={(currentPassVisible) => this.setState({ currentPassVisible })}
              />
            </div>
            <div className="position-relative formField-container d-flex">
              <label
                className={`floating-label ${
                  this.state.pass == '' ? 'hidden' : ''
                }`}
              >
                Новый пароль
              </label>
              <input
                type={this.state.passVisible ? "text" : "password"}
                className={`inputUnderLine`}
                placeholder="Новый пароль"
                value={this.state.pass}
                onChange={this.onChangeNewPassword}
                maxLength={128}
              />
              <PasswordMask
                isInputOutlined={true}
                passwordVisible={this.state.passVisible}
                setPasswordVisible={(passVisible) => this.setState({ passVisible })}
              />
            </div>
            <div className="position-relative formField-container mb-0 d-flex">
              <label
                className={`floating-label ${
                  this.state.repeatPass == '' ? 'hidden' : ''
                }`}
              >
                Подтвердите пароль
              </label>
              <input
                type={this.state.repeatPassVisible ? "text" : "password"}
                className={`inputUnderLine`}
                placeholder="Подтвердите пароль"
                value={this.state.repeatPass}
                onChange={this.onChangeConfirmNewPassword}
                maxLength={128}
              />
              <PasswordMask 
                isInputOutlined={true}
                passwordVisible={this.state.repeatPassVisible}
                setPasswordVisible={(repeatPassVisible) => this.setState({ repeatPassVisible })}
              />
            </div>
            <PasswordInstructions passwordErrorTypes={this.state.passwordErrorTypes} />
            <div className="text-danger">{this.state.errorMessage}</div>
            <div className="popupSubmitBtns d-flex justify-content-between align-items-center">
              <div
                className="popupCancel"
                onClick={() => this.togglePopup('isShowPopupChangePassword')}
              >
                Отмена
              </div>
              <div
                className="popupSubmitBtn popupSubmitBtn-ok"
                onClick={this.onSubmitChangePassword}
              >
                Сохранить
              </div>
            </div>
          </Popup>
        ) : null}
        {!this.state.pageError ? (
          <div>
            <div className="title card-content">Профиль</div>
            <div className="card card-content">
              <div className="card-body">
                <Baron>
                  {!this.state.isLoading ? (
                    <div>
                      {this.props.user.user.roles.includes("trader") && (
                        <div className="anketaChange-subtitleContainer d-flex justify-content-between align-items-center">
                          <div className="anketaChange-subtitle">Анкетные данные</div>
                          {process.env.ANKETA == 'yes' &&
                            !isCrypto && (
                              <span
                                className="addressHandWriting"
                                onClick={() =>
                                  this.togglePopup('isShowPopupChange')
                                }
                              >
                                Изменить
                              </span>
                            )}
                        </div>
                      )}
                      {this.props.user.user.roles.includes("trader") && (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="position-relative formField-container">
                              <label
                                className={`floating-label ${
                                  this.state.user.last_name == '' ? 'hidden' : ''
                                }`}
                              >
                                Фамилия
                              </label>
                              <input
                                type="text"
                                className={`inputUnderLine`}
                                placeholder="Фамилия"
                                readOnly={true}
                                value={this.state.user.last_name}
                              />
                            </div>
                            <div className="position-relative formField-container">
                              <label
                                className={`floating-label ${
                                  this.state.user.first_name == '' ? 'hidden' : ''
                                }`}
                              >
                                Имя
                              </label>
                              <input
                                type="text"
                                className={`inputUnderLine`}
                                placeholder="Имя"
                                readOnly={true}
                                value={this.state.user.first_name}
                              />
                            </div>
                            <div className="position-relative formField-container">
                              <label
                                className={`floating-label ${
                                  this.state.user.middle_name == ''
                                    ? 'hidden'
                                    : ''
                                }`}
                              >
                                Отчество
                              </label>
                              <input
                                type="text"
                                className={`inputUnderLine`}
                                placeholder="Отчество"
                                readOnly={true}
                                value={this.state.user.middle_name}
                              />
                            </div>
                            {isCrypto ? null : (
                              <div className="position-relative formField-container">
                                <label
                                  className={`floating-label ${
                                    this.state.user.skype == '' ? 'hidden' : ''
                                  }`}
                                >
                                  Ник в рейтинге
                                </label>
                                <input
                                  type="text"
                                  className={`inputUnderLine`}
                                  placeholder="Ник в рейтинге"
                                  value={this.state.user.rating_nickname}
                                  onChange={this.onChangeNickname}
                                  onBlur={() => {
                                    if (
                                      this.state.user.rating_nickname !=
                                      this.state.originalNickname
                                    )
                                      this.saveData('nickname');
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-lg-6">
                            <div className="position-relative formField-container">
                              <label
                                className={`floating-label ${
                                  this.state.user.email == '' ? 'hidden' : ''
                                }`}
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                className={`inputUnderLine`}
                                placeholder="Email"
                                readOnly={true}
                                value={this.state.user.email}
                              />
                            </div>
                            <div className="position-relative formField-container">
                              <label
                                className={`floating-label ${
                                  this.state.user.skype == '' ? 'hidden' : ''
                                }`}
                              >
                                Skype
                              </label>
                              <input
                                type="text"
                                className={`inputUnderLine`}
                                placeholder="Skype"
                                value={this.state.user.skype}
                                onChange={this.onChangeSkype}
                                onBlur={() => {
                                  if (
                                    this.state.user.skype !=
                                    this.state.originalSkype
                                  )
                                    this.saveData('skype');
                                }}
                              />
                            </div>
                            <div className="position-relative formField-container">
                              <label
                                className={`floating-label ${
                                  this.state.user.phone == '' ? 'hidden' : ''
                                }`}
                              >
                                Телефон
                              </label>
                              <input
                                type="text"
                                className={`inputUnderLine`}
                                placeholder="Телефон"
                                readOnly={true}
                                value={this.state.user.phone}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="anketaChange-subtitleContainer">
                        <div className="anketaChange-subtitle">Настройки</div>
                        {!this.props.user.products_disabled.includes("terminal") ? <div className="hintBlock">
                          <div className="hintBlock__text">Используйте логин и пароль от личного кабинета при входе в терминал</div>
                        </div> : null}
                      </div>
                      {isCrypto ? null : (
                        <div className="settings-liveDataContainer d-flex justify-content-between align-items-center mb-1">
                          <div className="settings-liveDataContainer-title">
                            Показывать уведомления Live-данных
                          </div>
                          <div className="switchContainer">
                            <label className="switch">
                              <input
                                type="checkbox"
                                default={this.state.isLiveNotifications}
                                value={this.state.isLiveNotifications}
                                checked={this.state.isLiveNotifications}
                                onChange={this.onChangeLiveNotifications}
                              />
                              <div className="slider"></div>
                            </label>
                          </div>
                        </div>
                      )}
                      {(this.props.user.user.roles.includes("bonus") || this.props.user.user.roles.includes("trader")) && (
                        <div className="settings-liveDataContainer d-flex justify-content-between align-items-center mb-4">
                          <div className="settings-liveDataContainer-title">
                          Скрывать баланс счетов
                          </div>
                          <div className="switchContainer">
                            <label className="switch">
                              <input
                                type="checkbox"
                                default={this.state.hideTradersBalances}
                                value={this.state.hideTradersBalances}
                                checked={this.state.hideTradersBalances}
                                onChange={this.onChangeHideTradersBalances}
                              />
                              <div className="slider"></div>
                            </label>
                          </div>
                        </div>
                      )}
                      {!this.props.user.products_disabled.includes("terminal") ? (<React.Fragment>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="position-relative formField-container">
                              <label
                                className={`floating-label`}
                              >
                                Адрес сервера терминала
                              </label>
                              <input
                                type="text"
                                className={`inputUnderLine`}
                                placeholder="Адрес сервера терминала"
                                readOnly={true}
                                value={this.props.settings.TERMINAL_SERVER_LINK}
                              />
                              <CopyToClipboard text={this.props.settings.TERMINAL_SERVER_LINK}>
                                <div>
                                  <img
                                    src="/images/ui/copyIcon.png"
                                    className="settings-copyIcon"
                                    onClick={(e) =>
                                      createTooltip(
                                        e,
                                        'Скопировано в буфер обмена'
                                      )
                                    }
                                    alt=""
                                  />
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="position-relative formField-container">
                              <label
                                  className={`floating-label`}
                              >
                                Резервный адрес сервера терминала
                              </label>
                              <input
                                  type="text"
                                  className={`inputUnderLine`}
                                  placeholder="Резервный адрес сервера терминала"
                                  readOnly={true}
                                  value={this.props.settings.TERMINAL_SERVER_LINK2}
                              />
                              <CopyToClipboard text={this.props.settings.TERMINAL_SERVER_LINK2}>
                                <div>
                                  <img
                                      src="/images/ui/copyIcon.png"
                                      className="settings-copyIcon"
                                      onClick={(e) =>
                                          createTooltip(
                                              e,
                                              'Скопировано в буфер обмена'
                                          )
                                      }
                                      alt=""
                                  />
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>) : null}
                      <div className="row">
                        <div className="col-lg-6">
                          {isCrypto ? null : (
                            <React.Fragment>
                              {this.state.platform_accounts.map(
                                (platform, index) => (
                                  <div
                                    key={platform.id}
                                    className="position-relative formField-container"
                                  >
                                    <label
                                      className={`floating-label ${
                                        platform.login == '' ? 'hidden' : ''
                                      }`}
                                    >
                                      Логин к терминалу
                                    </label>
                                    <input
                                      type="text"
                                      className={`inputUnderLine`}
                                      placeholder="Логин к терминалу"
                                      readOnly={true}
                                      value={platform.login}
                                    />
                                    <CopyToClipboard text={platform.login}>
                                      <img
                                        src="/images/ui/copyIcon.png"
                                        className="settings-copyIcon"
                                        onClick={(e) =>
                                          createTooltip(
                                            e,
                                            'Скопировано в буфер обмена'
                                          )
                                        }
                                        alt=""
                                      />
                                    </CopyToClipboard>
                                  </div>
                                )
                              )}
                            </React.Fragment>
                          )}
                          <div className="position-relative formField-container">
                            <label
                              className={`floating-label ${
                                this.state.user.email == '' ? 'hidden' : ''
                              }`}
                            >
                              Логин к сайту
                            </label>
                            <input
                              type="text"
                              className={`inputUnderLine`}
                              placeholder="Логин к сайту"
                              readOnly={true}
                              value={this.state.user.email}
                            />
                            <CopyToClipboard text={this.state.user.email}>
                              <div>
                                <img
                                  src="/images/ui/copyIcon.png"
                                  className="settings-copyIcon"
                                  onClick={(e) =>
                                    createTooltip(
                                      e,
                                      'Скопировано в буфер обмена'
                                    )
                                  }
                                  alt=""
                                />
                              </div>
                            </CopyToClipboard>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {isCrypto ? null : (
                            <React.Fragment>
                              {this.state.platform_accounts.map(
                                (platform, index) => (
                                  <div
                                    key={platform.id}
                                    className="settings-changePassTitle position-relative formField-container"
                                  >
                                    <span
                                      onClick={() => {
                                        this.togglePopup(
                                          'isShowPopupChangePassword'
                                        );
                                        this.setState({ currAccName: platform.id });
                                      }}
                                    >
                                      Сменить пароль
                                    </span>
                                  </div>
                                )
                              )}
                            </React.Fragment>
                          )}
                          <div className="settings-changePassTitle position-relative formField-container">
                            <span
                              onClick={() => {
                                this.togglePopup('isShowPopupChangePassword');
                                this.setState({ currAccName: 'website' });
                              }}
                            >
                              Сменить пароль
                            </span>
                          </div>
                        </div>
                      </div>
                      {isCrypto ? null : (
                        <React.Fragment>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="position-relative formField-container">
                                <label
                                >
                                  Бонус ID
                                </label>
                                {this.state.bonusIds.map(({ bonus_id }) => {
                                  return (
                                    <div className="d-flex bonusIdItem">
                                      <span className="bonusIdItemText" style={{ width: this.state.bonusIdItemWidth }}>{bonus_id}</span>
                                      <div className="bonusIdItemIcon ml-2"
                                        onClick={() => {
                                          this.removeBonusId(bonus_id);
                                        }}
                                      >
                                        <img src="/images/ui/dashboard-close-icon.svg" />
                                      </div>
                                    </div>
                                  )
                                })}
                                {this.state.bonusIds.length === 0 || this.state.bonusIdInputVisible ? (
                                  <input
                                    type='tel'
                                    pattern="^-?[0-9]\d*\.?\d*$"
                                    className={`inputUnderLine mt-1`}
                                    placeholder="Бонус ID"
                                    value={this.state.bonusId}
                                    onChange={this.onChangeBonusId}
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter' && this.state.bonusId !== '') {
                                        this.addBonusId(this.state.bonusId);
                                      }
                                    }}
                                    onBlur={() => {
                                      if (this.state.bonusId !== '') {
                                        this.addBonusId(this.state.bonusId);
                                      }
                                    }}
                                  />
                                ) : null}
                                <div className="addBonusIdInput" onClick={this.addBonusIdInput}>
                                  Добавить ещё
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="position-relative formField-container">
                                <label
                                >
                                  USDT кошелек
                                </label>
                                <input
                                  type="text"
                                  className={`inputUnderLine`}
                                  placeholder="USDT кошелек"
                                  value={this.state.usdtWallet}
                                  onChange={this.onChangeUsdtWallet}
                                  onBlur={() => {
                                    if (
                                      this.state.usdtWallet !=
                                      this.state.originalUsdtWallet
                                    )
                                      this.saveData('usdtWallet');
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                      {this.state.googleAuthRemoveModalIsOpened && this.props.user.user.roles.length ? (
                        <Popup
                          closePopup={() => this.togglePopup('googleAuthRemoveModalIsOpened', true)}
                          popupClass="googleAuth"
                        >
                          <div className="popup-title mb-3">Вы точно хотите отключить Google Authenticator?</div>
                          <div className="mt-3">
                            Будут мгновенно заблокированы после отключения двухфакторной аутентификации:
                          </div>
                          <ul className="mt-2 ml-4">
                            <li>Все операции на вывод средств</li>
                            {this.props.user.user.roles.includes("trader") && <li>Доступ к API key</li>}
                          </ul>
                          <div className="popupSubmitBtns d-flex justify-content-between align-items-center">
                            <div
                              className="popupCancel"
                              onClick={() => this.togglePopup('googleAuthRemoveModalIsOpened', true)}
                            >
                              Отмена
                            </div>
                            <Link to="/app/profile/google_auth_remove">
                              <div
                                className="popupSubmitBtn popupSubmitBtn-ok"
                              >
                                Продолжить
                              </div>
                            </Link>
                          </div>
                        </Popup>
                      ) : null}
                      <div className="row">
                        <div className="col-6 pr-2">
                          <div>Google Authenticator</div>
                          <div className="mt-1" style={{ fontSize: '10px' }}>
                            {this.props.user.user.roles.length === 0 
                              ? 'Используется для безопасного входа в аккаунт.'
                              : this.props.user.user.roles.includes("trader")
                              ? 'Используется для безопасного входа в аккаунт, доступа к API key и операций вывода.'
                              : this.props.user.user.roles.includes("bonus")
                              ? 'Используется для безопасного входа в аккаунт и операций вывода.'
                              : null
                            }
                          </div>
                        </div>
                        {!this.props.user.user.two_factor_auth ? (
                          <div className="col-6 pl-2 position-relative d-flex flex-column justify-content-end">
                            <div className="d-flex align-items-start text-danger text-right ml-auto position-relative">
                              <img className="inaccessibleIcon" src="/images/ui/inaccessible.png" />
                              Выключен
                            </div>
                            <Link
                              to="/app/profile/google_auth_add"
                            >
                              <div
                                className="settings-googleAuthBtn position-relative formField-container mt-2"
                              >
                                <span>
                                  Включить
                                </span>
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <div className="col-6 pl-2 position-relative d-flex flex-column justify-content-end">
                            <div className="d-flex align-items-start text-success text-right ml-auto position-relative">
                              <img className="profileCheckIcon filterToGreen" src="/images/ui/check-icon-black.png" height="12" />
                              Включен
                            </div>
                            <div
                              className="settings-googleAuthBtn position-relative formField-container mt-2"
                            >
                              {this.props.user.user.roles.length === 0  ? (
                                  <Link to="/app/profile/google_auth_remove">
                                    <span>
                                      Удалить
                                    </span>
                                  </Link>
                                ) : (
                                  <span
                                    onClick={() => {
                                      this.setState({ googleAuthRemoveModalIsOpened: true });
                                    }}
                                  >
                                    Удалить
                                  </span>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="text-center spinner-container isLoadingSpinner">
                      <span>
                        <img
                          src="/images/ui/load-spinner.png"
                          className="spinner"
                          alt=""
                        />
                      </span>
                    </div>
                  )}
                </Baron>
              </div>
            </div>
          </div>
        ) : (
          <h3 className="text-center text-danger">Произошла ошибка</h3>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    user: state.user,
    hideTradersBalances: state.general.hideTradersBalances
  };
};

const mapDispatchToProps = (dispatch) => ({
  setHideTradersBalances: (hideTradersBalances) => dispatch(setHideTradersBalances(hideTradersBalances)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
