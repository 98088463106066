import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';

import NotAuthRoute from './NotAuthRoute';
import AuthRoute from './AuthRoute';
import PublicRoute from './PublicRoute';
import LandingPage from '../components/LandingPage';
import SetPasswordPage from '../components/SetPasswordPage';
import RestorePasswordPage from '../containers/RestorePasswordPage';
import DashboardPage from '../components/DashboardPage';
import TransactionsPage from '../containers/TransactionsPage';
import ProfilePage from '../components/ProfilePage';
import TradingSettingsPage from '../components/TradingSettingsPage';
import FinresPage from '../containers/FinresPage';
import DailyFinresPage from '../containers/DailyFinresPage';
import NotFoundPage from '../components/NotFoundPage';
import BroadcastsPage from '../containers/BroadcastsPage';
import NewsPage from '../containers/NewsPage';
import AnketaFormPage from '../components/AnketaFormPage';
import DocumentsStatic from '../components/DocumentsStatic';
import DocumentsMovingPage from '../components/documents/DocumentsMovingPage';
import DocumentCreatePage from '../components/documents/DocumentCreatePage';
import DocumentPage from '../components/documents/DocumentPage';
import AnalysisPage from '../containers/AnalysisPage';
import AppFilesPage from '../containers/AppFilesPage';
import TestTextPage from '../containers/TestTextPage';
import PersonalFilesUpdate from '../components/PersonalFilesUpdate';
import ShopListPage from '../containers/ShopListPage';
import ShopItemPage from '../containers/ShopItemPage';
import ShopItemBuyPage from '../containers/ShopItemBuyPage';
import ShopSuccessPage from '../containers/ShopSuccessPage';
import ShopContactsPage from '../containers/ShopContactsPage';
import RatingPage from '../containers/RatingPage';
import TechPage from '../containers/TechPage';
import SpendBonuses from '../components/SpendBonuses';
import SpendBonusesCreate from '../components/SpendBonusesCreate';
import SpendBonusesAdditional from '../components/SpendBonusesAdditional';
import WithdrawAssets from '../components/WithdrawAssets';
import WithdrawAssetsCreate from '../components/WithdrawAssetsCreate';
import WithdrawAssetsAdditional from '../components/WithdrawAssetsAdditional';
import DashboardEmptyPage from '../components/DashboardEmptyPage';
import LeveragePage from '../components/LeveragePage';
import InnerTransferCreate from '../components/InnerTransferCreate';
import GoogleAuthAddPage from '../components/GoogleAuthAddPage';
import GoogleAuthRemovePage from '../components/GoogleAuthRemovePage';
import BonusProgram from '../components/BonusProgram';
import ConfirmRegisterPage from '../components/ConfirmRegisterPage';
import RequestBonusProgram from '../components/RequestBonusProgram';
import PositionsAndOrdersPage from '../components/PositionsAndOrdersPage';
import CryptoFinresPage from '../containers/CryptoFinresPage';

export const history = createHistory();

const isCrypto = true

const AppRouter = () => {
  const isPageAvailable = (user, role) => {
    if (role == 'terminal') return !user.products_disabled.includes('terminal')
    return user.user.roles.includes(role) && !user.products_disabled.includes(role)
  }

  return (
    <Router history={history}>
      <Switch>
        <PublicRoute path="/" component={LandingPage} exact={true} />
        <PublicRoute path="/app/confirm_register/:registration_confirmation_token" component={ConfirmRegisterPage} />
        {!isCrypto && <PublicRoute path="/shop" component={ShopListPage} exact={true} />}
        {!isCrypto && <PublicRoute path="/shop/contacts" component={ShopContactsPage} />}
        {!isCrypto && <PublicRoute path="/shop/success" component={ShopSuccessPage} />}
        {!isCrypto && <PublicRoute path="/shop/:key" component={ShopItemPage} exact={true} />}
        {!isCrypto && <PublicRoute path="/shop/:key/buy" component={ShopItemBuyPage} />}
        {/*{!isCrypto && <PublicRoute path="/survey" component={SurveyPage} />}*/}
        <NotAuthRoute path="/setpassword/:key" component={SetPasswordPage} />
        <NotAuthRoute path="/restore-password" component={RestorePasswordPage} />
        <AuthRoute path="/app/" component={DashboardEmptyPage} exact={true} />
        <AuthRoute path="/app/trading" component={PositionsAndOrdersPage} exact={true} checkFunction={(user) => isPageAvailable(user, "trader")} />
        {!isCrypto && <AuthRoute path="/app/test-text" component={TestTextPage} />}
        <AuthRoute path="/app/transactions" component={TransactionsPage} checkFunction={(user) => isPageAvailable(user, "trader") || isPageAvailable(user, "bonus")} />
        <AuthRoute path="/app/leverage" component={LeveragePage} checkFunction={(user) => isPageAvailable(user, "trader")} />
        <AuthRoute path="/app/profile/google_auth_remove" component={GoogleAuthRemovePage} />
        <AuthRoute path="/app/profile/google_auth_add" component={GoogleAuthAddPage} />
        <AuthRoute path="/app/profile" component={ProfilePage} />
        <AuthRoute path="/app/trading-settings" component={TradingSettingsPage} checkFunction={(user) => isPageAvailable(user, "trader")} />
        {!isCrypto && <AuthRoute path="/app/daily-finres" component={DailyFinresPage} />}
        {!isCrypto && <AuthRoute path="/app/finres" component={FinresPage} />}
        {!isCrypto && <AuthRoute path="/app/broadcasts/schedule" component={BroadcastsPage} />}
        {!isCrypto && <AuthRoute path="/app/broadcasts/courses" component={BroadcastsPage} />}
        {!isCrypto && <AuthRoute path="/app/broadcasts/master-classes" component={BroadcastsPage} />}
        {!isCrypto && <AuthRoute path="/app/broadcasts/analyzes" component={BroadcastsPage} />}
        {!isCrypto && <AuthRoute path="/app/broadcasts/webinars" component={BroadcastsPage} />}
        <AuthRoute path="/app/finres" component={CryptoFinresPage} checkFunction={(user) => isPageAvailable(user, "trader")} />
        <AuthRoute path="/app/news" component={NewsPage} />
        <AuthRoute path="/app/profile-form" component={AnketaFormPage} checkFunction={(user) => (user.form_status === "NEED_UPDATE" || user.form_status === "NOT_CREATED" || user.form_status === "NEED_REPLACE") && !user.products_disabled.includes("trader")} />
        {!isCrypto && <AuthRoute path="/app/documents/static" component={DocumentsStatic} />}
        {!isCrypto && <AuthRoute path="/app/documents/moving" component={DocumentsMovingPage} />}
        {!isCrypto && <AuthRoute path="/app/documents/create" component={DocumentCreatePage} />}
        {!isCrypto && <AuthRoute path="/app/documents/:key" component={DocumentPage} />}
        {!isCrypto && <AuthRoute path="/app/analysis/daily" component={AnalysisPage} />}
        {!isCrypto && <AuthRoute path="/app/analysis/symbols" component={AnalysisPage} />}
        {!isCrypto && <AuthRoute path="/app/analysis/equity" component={AnalysisPage} />}
        <AuthRoute path="/app/app-files" component={AppFilesPage} checkFunction={(user) => isPageAvailable(user, "terminal")} />
        {!isCrypto && <AuthRoute path="/app/rating" component={RatingPage} />}
        {!isCrypto && <AuthRoute path="/app/tech" component={TechPage} />}
        <AuthRoute path="/app/spend-bonuses/create" component={SpendBonusesCreate} checkFunction={(user) => isPageAvailable(user, "bonus")} />
        <AuthRoute path="/app/spend-bonuses/:id" component={SpendBonusesAdditional} checkFunction={(user) => isPageAvailable(user, "bonus")} />
        <AuthRoute path="/app/spend-bonuses" component={SpendBonuses} checkFunction={(user) => isPageAvailable(user, "bonus")} />
        <AuthRoute path="/app/bonus-program-request" component={RequestBonusProgram} checkFunction={(user) => (user.bonus_status === "NEED_UPDATE" || user.bonus_status === "NOT_CREATED") && !user.products_disabled.includes("bonus")} />
        <AuthRoute path="/app/bonus-program" component={BonusProgram} checkFunction={(user) => isPageAvailable(user, "bonus")} />
        <AuthRoute path="/app/withdraw-assets/create" component={WithdrawAssetsCreate} checkFunction={(user) => isPageAvailable(user, "trader")} />
        <AuthRoute path="/app/withdraw-assets/:id" component={WithdrawAssetsAdditional} checkFunction={(user) => isPageAvailable(user, "trader")} />
        <AuthRoute path="/app/withdraw-assets" component={WithdrawAssets} checkFunction={(user) => isPageAvailable(user, "trader")} />
        <AuthRoute path="/app/inner-transfer/create" component={InnerTransferCreate} checkFunction={(user) => isPageAvailable(user, "trader")} />
        <AuthRoute
            path="/app/personal-files-update"
            component={PersonalFilesUpdate}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  )
};

export default AppRouter;
